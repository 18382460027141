//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import blok from '~/mixins/blok'
import { last } from '~/utils/array'

export default {
  mixins: [blok],
  data () {
    return {
      templates: []
    }
  },
  fetchKey (getCounter) {
    return `blok-section-templates:${this.blok._uid}:${getCounter('blok-section-templates')}`
  },
  async fetch () {
    if (this.templates.length) {
      return
    }

    if (!this.blok.templates) {
      return
    }

    const ids = this.blok.templates.split('\n')

    const templates = []

    for (const idIndex in ids) {
      const id = ids[idIndex]
      if (!id) { continue }

      try {
        templates.push(await this.$api.getTemplate(last(id.split('/')), null, {}).then(r => ({
          id: r.data.data.id,
          name: r.data.data.attributes.name,
          slug: r.data.data.attributes.slug,
        })))
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('Template could not be loaded in BlokSectionTemplatesFeatured: ' + id, e.message)
      }
    }

    this.templates = templates
  }
}
